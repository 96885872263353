import OrderDetail from './OrderDetail';
import logo from './logo.svg';
import './custom.scss'

function App() {
  return (
    <>
        <OrderDetail />
    </>
  );
}

export default App;
