import {get_host} from './base';

async function fetch_api( api, method, body_in_string ) {
    const origin_host = get_host();
    const full = `https://${origin_host}${api}`;
    const payload = {method: method};
    if( method == 'POST' ) payload.body = body_in_string;
    const resp = await fetch( full, payload );
    const result = await resp.json();
    return result;
}

export default fetch_api;
