function RenderLabel( label ) {
    const parts = label.split('/');
    if( parts[0].toUpperCase() === "USPS" ) {
        return (<a target="_blank" href={"https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1="+parts[1]}>{label}</a>)
    } else {
        return (<div>{label}</div>)
    }
}
function RenderTracking( merchant_order ) {
    var ship_info = JSON.parse( merchant_order.shipping_info_json );
    var labels = ship_info.labels || [];
    return (
        <div>
        {labels.map((x) => (
        <div>
        {RenderLabel( x )}
        </div>
        ) )}
        </div>
    )

}
function CovertAddressToHumanReadable( shipping_address_json ) 
{
    var obj = JSON.parse( shipping_address_json );
    var addrs = [];
    var tmp = [];
    var ADR_L_PREFIX = 'address_line_';
    var ADMIN_AREA_PREFIX = 'admin_area_';
    for( var i = 1 ; i <= 3 ; i++ ) {
        if( obj.address[ADR_L_PREFIX+i] ) {
            tmp.push( obj.address[ADR_L_PREFIX+i] )
        }
    }
    addrs.push( tmp.join(' ') );

    tmp = [];
    for( var i = 1 ; i <= 3 ; i++ ) {
        if( obj.address[ADMIN_AREA_PREFIX+i] ) {
            tmp.push( obj.address[ADMIN_AREA_PREFIX+i] )
        }
    }
    addrs.push( tmp.join(' ') );
    addrs.push(obj.address.country_code);
    addrs.push(obj.address.postal_code);
    addrs = addrs.filter( (x) => x );
    return addrs.join(", ");
}

function ShippingInfo( {merchant_order, setOrder} ) {
    if( merchant_order.status == 'CREATED' ) {
        return (
        <>
            <div class="container">
            <div class="card border border-info">
                <div class="card-header text-bg-info">
                    One Last Step 
                </div>
                <div class="card-body">
                    <p class="card-text">We are expecting the result..</p>
                    <div class="text-center"><a href={merchant_order.pay_channel_checkout_link} target="_blank" class="btn btn-primary text-center">CHECKOUT</a></div>
                </div>
            </div>
            </div>
        </>)
    } else if( merchant_order.status == 'PENDING_SHIP' ) {
        return (
        <>
            <div class="container">
            <div class="card border border-primary">
                <div class="card-header text-bg-primary">
                    Ordered Success
                </div>
                <div class="card-body">
                    <h5 class="card-title">Thank You {JSON.parse(merchant_order.shipping_address_json).name.full_name}</h5>
                    <p class="card-text">Your order will be shipped to {CovertAddressToHumanReadable(merchant_order.shipping_address_json)}</p>
                </div>
            </div>
            </div>
        </>)
    } else if( merchant_order.status == 'SHIPPED' ) {
        return (
        <>
            <div class="container">
            <div class="card border border-success">
                <div class="card-header text-bg-success">
                    Order On The Way
                </div>
                <div class="card-body">
                    <h5 class="card-title">Thank You {JSON.parse(merchant_order.shipping_address_json).name.full_name}</h5>
                    <p class="card-text">Your order is on the way to {CovertAddressToHumanReadable(merchant_order.shipping_address_json)}</p>
                    {RenderTracking(merchant_order)}
                </div>
            </div>
            </div>
        </>)
    }
}

export default ShippingInfo;
