import BigNumber from 'bignumber.js';
function ShowPrice( quantity, origin, final_price ) {
    console.log( quantity, origin, final_price );
    const b_origin = new BigNumber( origin );
    const b_final = new BigNumber( final_price );
    const b_op = b_origin.times( quantity );
    const b_fp = b_final.times( quantity );
    if( !b_op.eq( b_fp ) ) {
        return (
            <>
            <div>
            <del>{'$'}{b_op.toFixed(2)}</del>
            </div>
            <div>
            <span>{'$'}{b_fp.toFixed(2)}</span>
            </div>
            </>

        )
    }
    return '$' + b_fp.toFixed(2);
    
}
function OrderItemList( {mo, setMo} ) {
    return (
        <>
            <div class="container">
                <table class="table">
                    <thead>
                        <tr class="table-light small-text">
                            <th scope="col" class="col-2"></th>
                            <th scope="col">PRODUCT</th>
                            <th scope="col"></th>
                            <th scope="col">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody class="mid-text" >
                        {mo.merchant_list.map((x) => (
                        <tr>
                            <td class="col-2">
                                <img src={x.image_url} class="rounded img-fluid" />
                            </td>
                            <td>
                                {x.product_title} {x.variant_title && x.variant_title.length > 0 ? "/ " + x.variant_title : "" }
                            </td>
                            <td>{x.quantity}</td>
                            <td>{ShowPrice(x.quantity,x.unit_origin_price,x.unit_final_price)}</td>
                        </tr>
                        ))}
                        <tr>
                            <th scope="row" colspan="3">Summary</th>
                            <td>
                                {ShowPrice(1,mo.total_origin_price,mo.total_discounted_price)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default OrderItemList;
