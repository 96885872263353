import queryString from 'query-string';
import React, { useState, useEffect } from 'react';
import {get_host} from './base';
import fetch_api from './shop-api.js';
import OrderItemList from './OrderItemList.js';
import ShippingInfo from './ShippingInfo.js';
import Header from './Header';
import ReactPixel from 'react-facebook-pixel';
const BigNumber = require( 'bignumber.js' );

function OrderDetail() {
    const [loading, setLoading] = useState(true);

    // eslint-disable-next-line
    const [merchant_order,setOrder] = useState(null);

    useEffect(() => {
        var pay_order_id = 0;
        var next_check_time = 5000;
        async function pollingStatus( ) {
            if( window.localStorage.getItem('no_polling') == '1' ) {
                console.log( 'no polling activate, would do nothing' );
                return;
            }
            const resp = await fetch_api( `/c/tpsu?pay_order_id=${pay_order_id}`);
            if( resp.status === 'NEW' ) {
                setTimeout( pollingStatus, next_check_time );
                next_check_time += 5000;
                if( next_check_time > 60*1000 ) next_check_time = 60*1000;
            } else {
                fetchData( true, resp.status );
            }
        }

        function now_report( ev, extra ) {
        try {
        var im = document.createElement("img");
        var params = {extra: extra};
        var product_pixels = window.localStorage.getItem("pixels_from_product");
        params.product_pixels = product_pixels;
        params.report_page = window.location.href;
        var p = encodeURIComponent(JSON.stringify(params));
        im.src = "https://report.trustone.shop/" + `report?org=${get_host()}&ev=` + ev + "&p=" + p + "&rnd=" + Date.now() + Math.random();
        }catch{
            console.error('something is wrong with', ev, params);
        }
        }

        async function fetchData( dont_fire_polling, pay_order_status ) {

            const parsed = queryString.parse( window.location.search );
            const id = parsed.id;
            const visit_key = parsed.visit_key;
            const result = await fetch_api( `/c/qmo?id=${id}&visit_key=${visit_key}`, 'GET' );
            setLoading(false);
            result.merchant_list = JSON.parse( result.merchant_list_as_json );
            //console.log( result );
            setOrder( result );
            pay_order_id = result.pay_order_id;
            if( !dont_fire_polling ) pollingStatus( );
            console.log( result.ad_channel_ext_as_json );
            var fbs = JSON.parse( result.ad_channel_ext_as_json );
            if( fbs.facebook ) {
                (fbs.facebook.fbpixels || '').split(',').map( (x) => {
                    console.log( `pixel init ${x}` );
                    ReactPixel.init( x, {}, {debug: true} );
                } )
                if( pay_order_status === 'SUCCESS' ) {
                    console.log('fire purchase event');
                    ReactPixel.fbq( 'track', 'Purchase', {currency: result.currency, value: parseFloat(result.total_discounted_price)}, {eventID: `purchase_${result.id}_${result.total_discounted_price}`} ); 
                    var extra = [{currency: result.currency, value: parseFloat(result.total_discounted_price)}, {eventID: `purchase_${result.id}_${result.total_discounted_price}`}]; 
                    now_report( 'facebook:Purchase', extra );
                } else {
                    now_report( 'FailPurchase' );
                }
            }
        }

        fetchData();

        //report
        {
            var im = document.createElement("img");
            im.src = "https://" + get_host() + "/report?&ev=checkout" + "&rnd=" + Date.now() + Math.random() + "&o=" + encodeURIComponent(window.location.href);
        }

    }, []);

    
    if( loading ) return (
        <div class="card">
        <div class="spinner-border text-success" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        </div>
    )
    return (
        <>
        <Header />
        <div class="container">
            <p class="mid8-text">Order #{merchant_order.id}</p>
        </div>
        <OrderItemList mo={merchant_order} setMo={setOrder}/>
        <ShippingInfo merchant_order={merchant_order} setOrder={setOrder}/>
        <br />
        <br />
        </>
    );
}

export default OrderDetail;

