import queryString from 'query-string';
import React, { useState, useEffect } from 'react';
import {get_host} from './base';
import fetch_api from './shop-api.js';

function Header() {
    const [shopName, setShopName] = useState('');
    const [shopLogo, setShopLogo] = useState('');
    const [shopUrl, setShopUrl] = useState('');
    useEffect( () => {
        async function fetchData() {
            const result = await fetch_api( '/c/shop-display', 'GET', '' );
            document.title = result.name;
            setShopName( result.name );
            setShopLogo( result.brand.logo.image.url );
            setShopUrl( result.primaryDomain.url );
       }

        fetchData();
    }, [] );
    return (
        <>
            <nav class="navbar container">
                <div class="container-fluid">
                    <a class="navbar-brand" href={shopUrl} target="_blank">
                        <img src={shopLogo} class="brand-image"/>
                    </a>
                </div>
            </nav>
        </>
    );
}

export default Header;
